import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import SearchIcon from '@material-ui/icons/Search';
import SecurityIcon from "@material-ui/icons/Security";
import WarningIcon from "@material-ui/icons/Warning";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";

const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin
  container: {
    padding: `0 ${theme.spacing(4)}px`,
  },
}));

function FeaturesSection(props) {
  const classes = useStyles();

  const items = [
    {
      title: "Smart Contract Audits",
      subtitle:
        "Cyvali conducts rigorous smart contract audits to identify vulnerabilities and strengthen security. Using a combination of static analysis, formal verification, and manual code review, we assess contract logic, access controls, and potential attack vectors. Our audits ensure compliance with industry best practices and support ecosystems like Ethereum, Solana, BNB Chain, and Polygon.",
      icon: SearchIcon,
      iconColor: "primary.main",
    },
    {
      title: "Protocol & dApp Security Assessments",
      subtitle:
        "We provide end-to-end security assessments for blockchain protocols and decentralized applications (dApps), covering business logic flaws, permission structures, and potential exploit pathways. Our approach includes penetration testing, attack surface analysis, and security best practices to safeguard DeFi platforms, NFT marketplaces, and other Web3 applications.",
      icon: SecurityIcon,
      iconColor: "#198754",
    },
    {
      title: "Threat Monitoring & Exploit Prevention",
      subtitle:
        "Beyond audits, Cyvali offers ongoing security monitoring to detect and mitigate threats in real time. We help protect against rug pulls, flash loan attacks, oracle manipulation, and other vulnerabilities. Our proactive approach strengthens project resilience and reinforces user trust in blockchain ecosystems.",
      icon: WarningIcon,
      iconColor: "#eed202",
    },
  ];
  

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className={classes.container}>
        <Grid container={true} justifyContent="center" spacing={7}>
          {items.map((item, index) => (
            <Grid item={true} xs={12} md={4} key={index}>
              <Box textAlign="center">
                <Box color={item.iconColor} fontSize="4.5rem">
                  <item.icon fontSize="inherit" />
                </Box>
                <Typography variant="h5" gutterBottom={true}>
                  {item.title}
                </Typography>
                <Box mt={3}>
                  <Typography variant="subtitle1">{item.subtitle}</Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
}

export default FeaturesSection;
