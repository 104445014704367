import React from "react";
import Meta from "./../components/Meta";
import Navbar from "./../components/Navbar";
import HeroSection from "./../components/HeroSection";
import FeaturesSection from "./../components/FeaturesSection";
import StatsSection from "./../components/StatsSection";
import ContactSection from "./../components/ContactSection";
import Footer from "./../components/Footer";

function IndexPage(props) {
  return (
    <>
      <Meta />
      <Navbar
        color="default"
        logo="cyvali-black-logo.png"
        logoInverted="cyvali-white-logo.png"
      />
      <HeroSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Blockchain Security & Smart Contract Audits"
        subtitle="Providing in-depth audits, vulnerability assessments, and proactive risk mitigation to secure and strengthen blockchain projects."
        image="tl-cyvali-no-arm.png"
        buttonText="Contact us"
        buttonColor="primary"
        buttonPath="https://twitter.com/Cyvali"
      />
      <FeaturesSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
      />
      {/* <HeroSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="CyVali Protect - Web Browser Extension"
        subtitle="Cyvali puts your safety first. Our advanced algorithms and insights detect risky websites, and we'll promptly show you a warning if you land on one. We'll guide you through your options, but the final decision is always yours: you can either proceed to the site despite the warning, or return to the previous page and block the site. With Cyvali, you have the power to browse safely and with confidence."
        image="cyvali-alert.png"
        buttonText="Install from chrome web store"
        buttonColor="primary"
        buttonPath="https://chrome.google.com/webstore/detail/cyvali-protect-emails-web/fpclombpnopnonnfkjagijcbfcemliia"
      /> */}
      {/* <StatsSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
      /> */}
      {/* <ContactSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Contact Us"
        subtitle=""
        buttonText="Send message"
        buttonColor="primary"
        showNameField={true}
      /> */}
      <Footer
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        copyright={`© ${new Date().getFullYear()} Cyvali`}
        logo="cyvali-black-logo.png"
        logoInverted="cyvali-white-logo.png"
        sticky={true}
      />
    </>
  );
}

export default IndexPage;
